import { useState, useCallback } from "react";
import FrameInstance from "../components/FrameInstance";
import PortalPopup from "../components/PortalPopup";
import "./IPhone13Mini4.css";

import { FaPhoneAlt, FaWhatsapp} from 'react-icons/fa';

const IPhone13Mini4 = () => {
  const [isFrameInstanceOpen, setFrameInstanceOpen] = useState(false);

  const openFrameInstance = useCallback(() => {
    setFrameInstanceOpen(true);
  }, []);

  const closeFrameInstance = useCallback(() => {
    setFrameInstanceOpen(false);
  }, []);

  const [showMore, setShowMore] = useState(false);


 
    const toggleReadMore = () => {
      setShowMore(!showMore);
    };
    
  const scrollToReadMore = () => {
    const readMoreWrapper = document.querySelector(".read-more-wrapper");
    if (readMoreWrapper) {
      readMoreWrapper.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="iphone-13-mini-4">
        <div className="icbaseline-phone-parent">
          <img
            className="icbaseline-phone-icon"
            alt=""
            src="/icbaselinephone.svg"
          />
          <img className="icbaseline-phone-icon" alt="" src="/tablermail.svg" />
          <img
            className="icbaseline-phone-icon"
            alt=""
            src="/pepiconspencilinternet.svg"
          />
          <img className="icbaseline-phone-icon" alt="" src="/eilocation.svg" />
        </div>
        <img className="dan-logo-1" alt="" src="/dan-logo-1@2x.png" />
        <img
          className="iphone-13-mini-4-child"
          alt=""
          src="/mekdem.png"
        />
        <div className="frame-parent">
          <div className="daniel-hailu-parent">
            <b className="solomek">Mekdem Getahun</b>
            <div className="founder-ceomek">{`Chief Technology Officer`}</div>
          </div>
          <div className="examplegmailcom-parent">
            <a className="examplegmailcommek" href="mailto:mekdemg@danenergyethiopia.com"> mekdemg@danenergyethiopia.com </a>
            <div className="examplegmailcommeked"> <FaWhatsapp className="whats"/>+251911692842</div>
            <div className="examplegmailcommeked"> <FaPhoneAlt className="phonebin" />+251911692842</div>
         
          </div>
        </div>
       <img className="browse-icon"  onClick={scrollToReadMore}  src="/browse.svg" />
     
        <b className="sister-companies">SISTER COMPANIES</b>
        <b className="dan-global">DAN Global</b>
        <b className="yeemma-injera">YeEmma Injera</b>
        <div className="lorem-ipsum-dolor">The 100% subsidiary of DAN Energy operates from the 149th floor of Burj Khalifa, Dubai. DAN Global provides global project management, turnkey solutions, IT transfer, JV, PPP, privatization, and outsourcing support to Enterprises, Governments, and NGOs worldwide." </div>
        <div className="lorem-ipsum-dolor1"> Company currently in operation and generating foreign currency for the country in a sustainable way.</div>
        <div className="iphone-13-mini-4-item" />
        <div className="ye-ema-injera-1-wrapper">
          <img
            className="ye-ema-injera-1"
            alt=""
            src="/ye-ema-injera-1@2x.png"
          />
        </div>
        <img
          className="iphone-13-mini-4-inner"
          alt=""
          src="/rectangle-4510@2x.png"
        />
        <div className="frame-group">
          <div className="eaglets-future-parent">
            <b className="eaglets-future">Eaglets Future</b>
            <div className="lorem-ipsum-dolor2">Eaglets Future empowers Africans with training in AI, Big Data, Cloud Computing, and Cybersecurity. Their team considers education and experience to guide trainees toward matching career opportunities. Seek advice from professionals for future success.</div>
          </div>
          <div className="eaglets-futue-logo-1-wrapper">
            <img
              className="eaglets-futue-logo-1-icon"
              alt=""
              src="/eagletsfutuelogo-1@2x.png"
            />
          </div>
        </div>
        <div className="vector-parent">
          <img className="frame-child" alt="" src="/vector-8.svg" />
          <div className="frame-item" />
          <div className="frame-inner" />
          <div className="frame-item" />
          <img className="vector-icon" alt="" src="/vector-7.svg" />
        </div>
        <div className="contact-us">contact us</div>
        <div className="div1">+251116676312 / +251116676321</div>
        <a className="infodanenergyethiopiacom" href="mailto:info@danenergyethiopia.com">info@danenergyethiopia.com
        </a>
        <a className="wwwdanenergyethiopiacom" href="https://www.danenergyethiopia.com/">www.danenergyethiopia.com</a>
        
        <div className="cmc-road-noc-container">
          <p className="cmc-road-noc">Cmc road, Noc building, addis abeba</p>
          <p className="cmc-road-noc">Ethiopia</p>
          <p className="blank-line">&nbsp;</p>
        </div>
        <div className="burj-khalifa-149-container">
          <p className="burj-khalifa-149">
            Burj Khalifa, 149 Floor, Downtown, Dubai, United Arab Emirates
          </p>
          <p className="blank-line">Directions</p>
        </div>
        <b className="focus-area">Focus area</b>
        <div className="lorem-ipsum-dolor3">
        DAN R&D focuses on the following strategic areas. 
        </div>
        <div className="read-more-wrapper">
        <div className="read-more">
          <div className="about-the-company-parent">
            <b className="about-the-company">About The Company</b>
            <div className="rectangle-div" />
          </div>
          <div className="lorem-ipsum-dolor-container">
            <p className="burj-khalifa-149">
            DAN R&D, founded six years ago by Daniel Hailu and Addis Getu, aims to be a leading African technology company. They focus on research, development, business, knowledge processing, management, talent, and project management.{' '}
              {!showMore && <span id="dots"></span>}
              {showMore && (
                <span id="more">
                  <br />
                  <br />
                  <h1 className="bagra">Background</h1> 
                  <p className="bagra1">To advance its mission of Developing African Nations, DAN R&D promotes entrepreneurship by providing coaching, resources, digital support, and seed funding to startups. They establish incubation centers catering to diverse businesses, with a focus on empowering youth, the underprivileged, and African women, aiming for sustainable development{' '}
                  </p>  <img className="imgaff" src="/read more.png"/>
           
                </span>
             
              )}
            </p>
            
          <div className={`hello`}>
  <div className="vivamimot">
  <div className={`vision-parent`} >
            <b className="daniel-hailu1">Vision</b>
            <div className="vector-group">
              <img className="frame-child1" alt="" src="/vector-5.svg" />
              <img className="image-1-icon" alt="" src="/image-1@2x.png" />
            </div>
            <div className="lorem-ipsum-dolor5">
            Dan Energy aspires to be a leading technology company in Africa by 2030.
            </div>
          </div>
          <div className={`motto-parent`} id="motto">
    
            <b className="daniel-hailu12">Motto</b>
            <div className="vector-container">
              <img className="frame-child2" alt="" src="/vector-5.svg" />
              <img className="images-2-icon" alt="" src="/images-2@2x.png" />
            </div>
            <div className="lorem-ipsum-dolor6">
            Empowering Life
            </div>
          </div>
          <div className={`mission-parent`} id="mission">
     
            <b className="daniel-hailu1">Mission</b>
            <div className="vector-container1">
              <img className="frame-child3" alt="" src="/vector-5.svg" />
              <img
                className="mission-target-icon-or-busines"
                alt=""
                src="/missiontargeticonorbusinessgoallogoinreddesignconceptonanisolatedwhitebackgroundeps10vector2byp04j-3@2x.png"
              />
            </div>
            <div className="lorem-ipsum-dolor-sit-amet-co-wrapper">
              <div className="lorem-ipsum-dolor7">
              This initiative aims to improve African lives through accessible technology solutions, infrastructure development, enhanced services, and skill-building.  </div>
            </div>
          </div>
          <div className={`values-parent`} id="values">
     
            <b className="daniel-hailu1">Values</b>
            <img className="frame-icon" alt="" src="/frame.svg" />
            <div className="lorem-ipsum-dolor8">
            Integrity, Team Spirit,
Resilience, Commitment, Innovation, Professionalism </div>
          </div>
          </div>
        </div>
          </div>
          <h1 className="read-more1" onClick={toggleReadMore}>
            {showMore ? "Read less" : "Read more"}
          </h1> 
          
         
      </div>
      </div>


        <img className="mask-group-icon" alt="" src="/mask-group@2x.png" />
        <img className="mask-group-icon1" alt="" src="/mask-group1@2x.png" />
        <img
          className="iphone-13-mini-4-child1"
          alt=""
          src="/vector-10@2x.png"
        />
        <img
          className="f1jwrsgwyaa-4cx-1-icon"
          alt=""
          src="/f1jwrsgwyaa-4cx-1@2x.png"
        />
        <div className="anima">
          <div className="anima-child" />
        </div>
        <div className="gallery">Gallery</div>
        <div className="photo-1-2023-09-22-15-55-06-parent">
          <img
            className="photo-1-2023-09-22-15-55-06-icon"
            alt=""
            src="/DSC00365.jpg"
          />
          <img
            className="photo-5-2023-09-21-17-49-34-icon"
            alt=""
            src="/photo-20221010-105019-3-11zon-rgwkod-1@2x.png"
          />
          <img className="download-1-icon" alt="" src="/download-1@2x.png" />
          <img
            className="dsc09779-11zon-yqealc-1-icon"
            alt=""
            src="/dsc09779-11zon-yqealc-1@2x.png"
          />
          <img
            className="photo-3-2023-09-22-16-03-28-icon"
            alt=""
            src="/photo-3-20230922-160328@2x.png"
          />
          <img
            className="photo-2022-10-10-10-50-19-3-11-icon"
            alt=""
            src="/F1jv6qmWYAMfv8L.jpg"
          />
        </div>
        <img
          className="carbonmaximize-icon"
          alt=""
          src="/carbonmaximize.svg"
          onClick={openFrameInstance}
        />
        <div className="frame-container">
          <div className="frame">
            <div className="ellipse-parent">
              <div className="frame-child4" />              
              <div className="frame-child6" />
              <div className="frame-child8" />
              <div className="frame-child10" />
              <div className="frame-child11" />
              <div className="frame-child12" />
              <div className="frame-child13" />
              <div className="frame-child14" />
              <div className="training-placement">{`Training and Placement `}</div>
              <div className="talent-hunt">{`Talent hunt and HR services `}</div>
              <div className="it-services">IT Services</div>
              <div className="development-of-msmes">
                Development of MSMEs for service industry
              </div>
              <div className="startups-incubation">{`Startups Incubation & Accelaration`}</div>
              <div className="digital-marketing">Digital Marketing</div>
              <div className="fintec">Engineering Service</div>
              <div className="manufacturingagri-tech-cons">{`Manufacturing (Agri-tech & Consumer electronics) `}</div>
            </div>
            <div className="ellipse-parent">
              <div className="frame-child4" />
              <div className="frame-child6" />
              <div className="frame-child8" />
              <div className="frame-child10" />
              <div className="frame-child11" />
              <div className="frame-child12" />
              <div className="frame-child13" />
              <div className="frame-child14" />
              <div className="training-placement">{`Research & Development `}</div>
              <div className="talent-hunt">{`Net zero by Women `}</div>
              <div className="it-services">Business development</div>
              <div className="development-of-msmes">
              BPO and KPO              </div>
              <div className="startups-incubation">{`Renewable energies `}</div>
              <div className="digital-marketing">Project Management </div>
              <div className="fintec">Electromechanical Work </div>
              <div className="manufacturingagri-tech-cons">{`Fintech `}</div>
            </div>
            <div className="ellipse-parent">
              <div className="frame-child4" />
             <div className="training-placement">{`Community service`}</div>
             </div>
          </div>
          <img className="line-icon" alt="" src="/line-1.svg" />
        </div>
        <img
  className={`mask-groupvariant2-icon ${showMore ? 'hidden' : ''}`}
  alt=""
  src="/mask-groupvariant2@2x.png"
/>






      </div>
      {isFrameInstanceOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeFrameInstance}
        >
          <FrameInstance onClose={closeFrameInstance} />
        </PortalPopup>
      )}
    </>
  );
};





export default IPhone13Mini4;
